import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const ADD_CONTENT_TO_PLAYLIST = gql`
  mutation addContentToPlaylist($id: ID!, $contents: [Json!]!) {
    addContentToPlaylist(id: $id, contents: $contents) {
      id
    }
  }
`;

export const UPDATE_CONTENT_LANGUAGE = gql`
  mutation updateContentLanguage($playlistContentId: ID!, $playlistId: ID!, $language: String!) {
    updateContentLanguage(
      playlistContentId: $playlistContentId
      playlistId: $playlistId
      language: $language
    ) {
      id
      language
    }
  }
`;

export const REMOVE_CONTENT_FROM_PLAYLIST = gql`
  mutation removeContentFromPlaylist(
    $id: ID!
    $playlistContentIds: [ID]
  ) {
    removeContentFromPlaylist(
      id: $id
      playlistContentIds: $playlistContentIds
    ) {
      id
    }
  }
`;

export const UPDATE_PLAYLIST_V2 = gql`
  mutation updatePlaylist(
    $id: ID!
    $title: String
    $isPublished: Boolean
    $isMultilingual: Boolean
    $hasUniqueChapterFeed: Boolean
    $disableAnalytic: Boolean
    $waitingThumbKey: String
    $rollOption: PlaylistRollOption
    $note: String
  ) {
    updatePlaylist(
      id: $id
      title: $title
      isPublished: $isPublished
      isMultilingual: $isMultilingual
      hasUniqueChapterFeed: $hasUniqueChapterFeed
      disableAnalytic: $disableAnalytic
      waitingThumbKey: $waitingThumbKey
      rollOption: $rollOption
      note: $note
    ) {
      id
      title
      note
      isPublished
      isMultilingual
      hasUniqueChapterFeed
      disableAnalytic
      waitingThumbKey
      waitingThumbCdnUrl
      rollOption
    }
  }
`;

export const CREATE_PLAYLIST_WITH_CONTENT = gql`
  mutation createPlaylistWithContent($name: String!, $contents: [Json]) {
    createPlaylistWithContent(name: $name, contents: $contents) {
      id
      publisher {
        id
      }
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($id: ID!) {
    deletePlaylist(id: $id) {
      id
    }
  }
`;