import { GetUserFromRecoil } from "../../recoil/user";
import { GetLiveCustomizationData } from "../store/live";
import { GetMedia } from "../store/media";
import { GetPlaylistData } from "../store/playlist";
import { GetWebTVInfos } from "../store/webTV";

const removeCapitalCase = (s) =>
  s === "webtv" ? "webTV" : String(s)[0].toLowerCase() + String(s).slice(1);
// TODO: Harmonize contentTypes & permissions

export const isMaster = (roles) => {
  if (!roles) return false;
  return roles.some((role) => role.name?.title === "Master");
};

export const isOwner = (roles) => {
  if (!roles) return false;
  return roles.some(
    (role) => role.name?.title === "Owner" || role.name?.title === "Master"
  );
};

export const isAdministrator = (roles) => {
  if (!roles) return false;
  return roles.some(
    (role) =>
      role.name?.title === "Owner" ||
      role.name?.title === "Master" ||
      role.name?.title === "Administrator"
  );
};

export const isEditor = (roles) => {
  if (!roles) return false;
  return roles.some(
    (role) =>
      role.name?.title === "Owner" ||
      role.name?.title === "Master" ||
      role.name?.title === "Administrator" ||
      role.name?.title === "Editor"
  );
};

export const isRedactor = (roles) => {
  if (!roles) return false;
  return roles.some(
    (role) =>
      role.name?.title === "Owner" ||
      role.name?.title === "Master" ||
      role.name?.title === "Administrator" ||
      role.name?.title === "Redactor"
  );
};

export const isCollaborator = (roles) => {
  if (!roles) return false;
  return roles.some((role) => role.name?.title === "Collaborator");
};

export const isModerator = (roles) => {
  if (!roles) return false;
  return roles.some(
    (role) =>
      role.name?.title === "Owner" ||
      role.name?.title === "Master" ||
      role.name?.title === "Administrator" ||
      role.name?.title === "SuperModerator" ||
      role.name?.title === "Moderator"
  );
};

export const isSuperModerator = (roles) => {
  if (!roles) return false;
  return roles.some((role) => role.name?.title === "SuperModerator");
};

export const isJustModerator = (roles) => {
  if (!roles) return false;
  return roles.every(
    (role) =>
      role.name?.title === "Moderator" || role.name?.title === "SuperModerator"
  );
};

const getPermissions = (role) => {
  return Object.keys(role.permissions).filter(
    (permission) => role.permissions[permission]
  );
};

export const isAllowToEdit = (contentType, publisherId = null) => {
  const { id, roles } = GetUserFromRecoil();

  if (isAdministrator(roles) || isOwner(roles) || isMaster(roles)) {
    return true;
  }

  if (isEditor(roles) && id === publisherId) {
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Editor")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  if (isCollaborator(roles) && id === publisherId) {
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Collaborator")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  if (isRedactor(roles)) {
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Redactor")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  return false;
};

export const isAllowToEditNew = (contentType, publisherId) => {
  const { id, roles } = GetUserFromRecoil();

  if (isAdministrator(roles) || isOwner(roles) || isMaster(roles)) {
    return true;
  }

  if (isEditor(roles) && id === publisherId) {
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Editor")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  if (isCollaborator(roles)) {
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Collaborator")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  return false;
};

export const isAllowToDelete = (publisherId = null) => {
  const { id, roles } = GetUserFromRecoil();

  if (isAdministrator(roles) || isOwner(roles) || isMaster(roles)) {
    return true;
  }

  if ((isEditor(roles) || isCollaborator(roles)) && id === publisherId) {
    return true;
  }

  return false;
};

export const isAllowToCreate = (contentType, publisher) => {
  const { id, roles } = publisher ? publisher : GetUserFromRecoil();

  if (isAdministrator(roles) || isOwner(roles) || isMaster(roles)) {
    return true;
  }

  if (isEditor(roles)) {
    if (publisher && publisher.id !== id) return;
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Editor")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  if (isCollaborator(roles)) {
    if (publisher && publisher.id !== id) return;
    const permissions = getPermissions(
      roles.find((role) => role.name?.title === "Collaborator")
    );
    return permissions.includes(removeCapitalCase(contentType));
  }

  return false;
};

export const isAllowToRead = (contentType, contentId) => {
  if (contentType === "media") {
    const { availableData: mediaData, loading: mediaLoading } =
      GetMedia(contentId);
    const { account } = GetUserFromRecoil();
    const isAllowed = mediaData?.account?.id === account?.id;
    return {
      allowLoading: mediaLoading,
      isAllowed,
    };
  } else if (contentType === "live") {
    const { availableData: liveData, loading: liveLoading } =
      GetLiveCustomizationData(contentId);
    const { account } = GetUserFromRecoil();
    console.log('liveData', liveData, 'account', account)
    const isAllowed = liveData?.account?.id === account?.id;

    return {
      allowLoading: liveLoading,
      isAllowed,
    };
  } else if (contentType === "playlist") {
    const { playlist: playlistData, loading: playlistLoading } =
      GetPlaylistData(contentId);
    const { account } = GetUserFromRecoil();
    const isAllowed = playlistData?.account?.id === account?.id;

    return {
      allowLoading: playlistLoading,
      isAllowed,
    };
  } else if (contentType.toLowerCase() === "webtv") {
    const { availableData: webTVData, loading: webTVLoading } =
    GetWebTVInfos(contentId);
    const { account } = GetUserFromRecoil();

    const isAllowed = webTVData?.account?.id === account?.id;

    return {
      allowLoading: webTVLoading,
      isAllowed,
    };
  }
};

export const newContentOwnerHasRightPermissions = (
  mediasCount,
  livesCount,
  playlistsCount,
  webTVsCount,
  newContentOwner
) => {
  if (isAdministrator(newContentOwner.roles)) return true;
  if (
    newContentOwner.roles.some((role) => role.name?.title === "Moderator") ||
    newContentOwner.roles.some((role) => role.name?.title === "Redactor")
  )
    return false;
  console.log(
    "mediasCount, livesCount, playlistsCount, webTVsCount",
    mediasCount,
    livesCount,
    playlistsCount,
    webTVsCount
  );
  if (mediasCount) {
    const isAllowed = isAllowToCreate("media", newContentOwner);
    if (!isAllowed) return;
  }

  if (livesCount) {
    const isAllowed = isAllowToCreate("live", newContentOwner);
    if (!isAllowed) return;
  }

  if (webTVsCount) {
    const isAllowed = isAllowToCreate("webTV", newContentOwner);
    if (!isAllowed) return;
  }

  if (playlistsCount) {
    const isAllowed = isAllowToCreate("playlist", newContentOwner);
    if (!isAllowed) return;
  }

  return true;
};
