import { notification } from "antd";

const getNotificationTitle = (type, callback, isMultiple) => {
  if (callback === "createPlaylist") {
    return "La playlist a été créée";
  } else if (callback === "fillPlaylist" || callback === "populatePlaylist") {
    return "La playlist a été remplie";
  } else if (callback === "addContentToWebtvGroup") {
    return "Les vidéos ont été ajoutées au groupe";
  } else if (callback === "addContentToWebtvSection") {
    return "Les vidéos ont été ajoutées à la section";
  } else if (callback === "addContentToWebtvTemplate") {
    return "La vidéos a été ajoutée au contenu mis en avant";
  } else if (callback === "duplicateLive") {
    return "Le direct a été dupliqué";
  } else if (callback === "delete-media") {
    if (isMultiple) {
      return "Les médias ont été supprimés";
    }
    return "Le média a été supprimé";
  } else if (callback === "delete-playlist") {
    if (isMultiple) {
      return "Les playlists ont été supprimées";
    }
    return "La playlist a été supprimée";
  } else if (callback === "delete-webtv") {
    if (isMultiple) {
      return "Les webTVs ont été supprimées";
    }
    return "La webTV a été supprimée";
  } else if (callback === "delete-live") {
    if (isMultiple) {
      return "Les directs ont été supprimés";
    }
    return "Le direct a été supprimé";
  } else if (callback === "quick-edit-media") {
    if (isMultiple) {
      return "Les médias ont été modifiés";
    }
    return "Le média a été modifié";
  } else if (callback === "quick-edit-playlist") {
    if (isMultiple) {
      return "Les playlists ont été modifiées";
    }
    return "La playlist a été modifiée";
  } else if (callback === "quick-edit-webtv") {
    if (isMultiple) {
      return "Les webTVs ont été modifiées";
    }
    return "La webTV a été modifiée";
  } else if (callback === "export-csv") {
    return "Le fichier CSV a été généré";
  } else if (callback === "send-reset-password-email") {
    return "Si un compte correspond à cette adresse email, un email de réinitialisation de mot de passe a été envoyé";
  } else if (callback === "upload-media") {
    return "Le fichier a été chargé avec succès et est en cours d'encodage";
  } else if (callback === "upload-version-error") {
    return "Vous ne pouvez charger qu'un seul fichier à la fois";
  } else if (callback === "upload-file") {
    return "Le fichier a été chargé avec succès";
  } else if (callback === "invalid-format") {
    return "Le fichier n'est pas au bon format";
  } else if (callback === "invalid-size") {
    return "Le fichier est trop volumineux";
  } else if (callback === "upload-aborted") {
    return "Le chargement du fichier a été annulé";
  } else if (type === "error") {
    return "Une erreur est survenue";
  } else if (callback === "multi-select-not-allowed") {
    return "Vous ne pouvez pas sélectionner plusieurs éléments pour ce contenu de webTV";
  } else if (callback === "max-selection-reached") {
    return "Vous avez atteint le nombre maximum de vidéos";
  } else if (callback === "createRollPreset") {
    return "Creation preset";
  } else if (callback === "upload-warning") {
    return "Vous avez un ou plusieurs fichiers en cours de chargement. Veuillez patienter avant quitter la page.";
  } else if (callback === "delete-transcript") {
    return "Le fichier de transcription a été supprimé";
  } else if (callback === "delete-subtitle") {
    return "Le fichier de sous-titre a été supprimé";
  } else if (callback === "delete-iframe") {
    return "L'iframe a été supprimé";
  } else if (callback === "generate-subtitles") {
    return "Sous-titres générés avec succès";
  } else if (callback === "upload-error") {
    return "Une erreur est survenue lors du chargement du fichier";
  } else if (callback === "graphql-error") {
    return "Une erreur est survenue";
  }
};

const getNotificationDescription = (type, callback) => {
  if (type === "error") {
    return "";
  }
  if (callback === "createPlaylist") {
    return "Vous avez été redirigé vers votre playlist.";
  } else if (callback === "fillPlaylist" || callback === "populatePlaylist") {
    //return "Les vidéos ont bien été ajoutées à la playlist";
  } else if (callback === "addContentToWebtv") {
  } else if (callback === "duplicateLive") {
    return "Vous avez été redirigé vers votre nouveau direct.";
  } else if (callback === "export-csv") {
    return "Le téléchargement va débuter dans quelques secondes";
  } else if (callback === "download-media") {
    return "Votre fichier est en cours de téléchargement.";
  } else if (callback === "createRollPreset") {
    return "Votre preset a été créé avec succès";
  }
};

export const openNotificationWithIcon = (type, callback, isMultiple) => {
  notification[type]({
    message: getNotificationTitle(type, callback, isMultiple),
    description: getNotificationDescription(type, callback, isMultiple),
  });
};
