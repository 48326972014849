import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "../../utils/useTranslation";

const LoaderSpin = ({
  size = "default",
  inline = false,
  noText = false,
  text = null,
}) => {
  const { t } = useTranslation("Commons");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: size === "default" ? 48 : 24 }} spin />
  );
  return (
    <div
      style={{
        maxWidth: "100vw",
        height: inline ? "initial" : "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin indicator={antIcon} />
      {!noText && (
        <p style={{ fontSize: size === "default" ? 16 : 14, margin: 16 }}>
          {text ? text : /* t("loading") */ t("loading")}
        </p>
      )}
    </div>
  );
};

export default LoaderSpin;
