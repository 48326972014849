import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const CREATE_MEDIA = gql`
  mutation CreateMedia(
    $mimeType: String!
    $size: Float!
    $fileName: String!
    $title: String
  ) {
    createMedia(
      mimeType: $mimeType
      size: $size
      fileName: $fileName
      title: $title
    ) {
      id
      title
      fileName
      privacity
      selectedThumbKey
      selectedThumbCdnUrl
      createdAt
      type
      isPublished
      size
      tags {
        id
        value
      }
      categories {
        id
        value
      }
      publisher {
        id
        usage {
          mediaCount {
            userMediaCount
            accountMediaCount
          }
        }
      }
      account {
        id
      }
    }
  }
`;

export const EDIT_MEDIA_SOURCE = gql`
  mutation EditMediaSource(
    $id: ID!
    $sourceKey: String!
    $type: String
    $fileName: String
    $isVersioning: Boolean
    $hasGetTranscript: Boolean
    $language: String
    $modelSize: String
    $autoPublish: Boolean
    $hasSubtitles: Boolean
  ) {
    editMediaSource(
      id: $id
      sourceKey: $sourceKey
      type: $type
      fileName: $fileName
      isVersioning: $isVersioning
      hasGetTranscript: $hasGetTranscript
      language: $language
      modelSize: $modelSize
      autoPublish: $autoPublish
      hasSubtitles: $hasSubtitles
    ) {
      id
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia(
    $id: ID!
    $title: String
    $cote: String
    $isPublished: Boolean
    $privacity: String
    $movie: String
    $director: String
    $author: String
    $composer: String
    $genre: String
    $metaType: String
    $date: String
    $copyright: String
    $comment: String
    $xyz: String
    $externalLink: String
    $startsAt: String
    $disableAnalytic: Boolean
    $selectedThumbKey: String
    $note: String
    $url: String
    $language: String
    $producer: String
    $postalCode: String
    $authorPseudo: String
    $authorEmail: String
    $authorPhone: String
    $authorAddress: String
    $authorPostalCode: String
    $authorCity: String
    $authorCountry: String
  ) {
    updateMedia(
      id: $id
      title: $title
      cote: $cote
      isPublished: $isPublished
      privacity: $privacity
      movie: $movie
      director: $director
      author: $author
      composer: $composer
      genre: $genre
      metaType: $metaType
      date: $date
      copyright: $copyright
      comment: $comment
      xyz: $xyz
      externalLink: $externalLink
      startsAt: $startsAt
      disableAnalytic: $disableAnalytic
      selectedThumbKey: $selectedThumbKey
      note: $note
      url: $url
      language: $language
      producer: $producer
      postalCode: $postalCode
      authorPseudo: $authorPseudo
      authorEmail: $authorEmail
      authorPhone: $authorPhone
      authorAddress: $authorAddress
      authorPostalCode: $authorPostalCode
      authorCity: $authorCity
      authorCountry: $authorCountry
    ) {
      id
      title
      cote
      metaType
      date
      isPublished
      selectedThumbKey
      selectedThumbCdnUrl
      privacity
      startsAt
      disableAnalytic
      note
      url
      language
      producer
      postalCode
      authorPseudo
      authorEmail
      authorPhone
      authorAddress
      authorPostalCode
      authorCity
      authorCountry
    }
  }
`;

export const ADD_MEDIA_THUMB = gql`
  mutation AddMediaThumb($id: ID!, $fileKey: String!) {
    addMediaThumb(id: $id, fileKey: $fileKey) {
      id
      selectedThumbKey
      selectedThumbCdnUrl
      thumbCdnUrls
      thumbKeys
    }
  }
`;

export const REMOVE_MEDIA_THUMB = gql`
  mutation removeMediaThumb($id: ID!, $fileKey: String!) {
    removeMediaThumb(id: $id, fileKey: $fileKey) {
      id
      selectedThumbKey
      selectedThumbCdnUrl
      thumbCdnUrls
      thumbKeys
    }
  }
`;

export const ADD_SUBTITLE = gql`
  mutation createSubtitle($mediaId: ID!, $fileKey: String!, $languageCode: String) {
    createSubtitle(mediaId: $mediaId, fileKey: $fileKey, languageCode: $languageCode) {
      id
      languageCode
      cdnUrl
    }
  }
`;

export const EDIT_SUBTITLE = gql`
  mutation EditSubtitle(
    $id: ID!
    $mediaId: ID!
    $languageCode: String
    $isPublished: Boolean
    $isDefault: Boolean
    $fileKey: String
    $mimeType: String
  ) {
    editSubtitle(
      id: $id
      mediaId: $mediaId
      languageCode: $languageCode
      isPublished: $isPublished
      isDefault: $isDefault
      fileKey: $fileKey
      mimeType: $mimeType
    ) {
      id
      languageCode
      isPublished
      isDefault
      cdnUrl
      key
    }
  }
`;

export const DELETE_SUBTITLE = gql`
  mutation DeleteSubtitles($ids: [ID!], $mediaId: ID!) {
    deleteSubtitles(ids: $ids, mediaId: $mediaId) {
      id
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($id: String!) {
    deleteMedia(id: $id) {
      id
    }
  }
`;

export const INIT_UPLOAD = gql`
  mutation initMultipartUpload(
    $mediaId: String!
    $fileName: String!
    $Size: Float!
    $mimeType: String!
  ) {
    initMultipartUpload(
      mediaId: $mediaId
      fileName: $fileName
      Size: $Size
      mimeType: $mimeType
    )
  }
`;

export const COMPLETE_UPLOAD = gql`
  mutation completeMultipartUpload(
    $mediaId: ID!
    $Key: String!
    $UploadId: String!
    $parts: [Json!]!
  ) {
    completeMultipartUpload(
      mediaId: $mediaId
      Key: $Key
      UploadId: $UploadId
      parts: $parts
    )
  }
`;
