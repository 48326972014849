import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_PLAYLIST_DATA = gql`
  query getPlaylistData($id: ID!) {
    playlist(id: $id) {
      id
      order
      note
      title
      waitingThumbKey
      waitingThumbCdnUrl
      isPublished
      isMultilingual
      contentCount
      mediaCount
      liveCount
      hasUniqueChapterFeed
      createdAt
      publisher {
        id
        firstName
        lastName
        pictureCdnUrl
      }
      account {
        id
      }
      embedSetting {
        id
      }
      pageSetting {
        id
        pageText
        pageLogoCdnUrl
        accentColor
      }
    }
  }
`;

export const GET_PLAYLIST_CONTENT = gql`
  query getPlaylistContents($id: ID!, $limit: Int, $cursor: String) {
    playlist(id: $id) {
      id
      title
      isMultilingual
      mediaCount
      liveCount
      waitingThumbCdnUrl
      playlistContents(limit: $limit, cursor: $cursor) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        playlistContent {
          order
          id
          type
          language
          media {
            id
            title
            selectedThumbCdnUrl
            publisher {
              firstName
              lastName
              pictureCdnUrl
            }
          }
          live {
            id
            title
            waitingThumbCdnUrl
            publisher {
              firstName
              lastName
              pictureCdnUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_PLAYLIST_INFOS = gql`
  query getPlaylistInfos($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      title
      waitingThumbKey
      waitingThumbCdnUrl
      pageSetting {
        id
        pageText
        pageTitle
      }
    }
  }
`;

export const GET_PLAYLIST_TITLE = gql`
  query getPlaylistTitle($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      title
    }
  }
`;
